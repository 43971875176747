import React from 'react'
import './Home.css'

export default function Home() {
    return (
        <div class="container glider">
            <div class="title-count">
                <h1>55</h1>
                <h2><a href="https://en.wikipedia.org/wiki/2020%E2%80%9321_Rangers_F.C._season">2020 &mdash; 2021</a></h2>
            </div>
            <footer>
                <span><a href="https://www.rangers.co.uk">rangers.co.uk</a></span>
            </footer>
        </div>
    )
}